exports.components = {
  "component---src-templates-blog-detail-js": () => import("./../../../src/templates/blogDetail.js" /* webpackChunkName: "component---src-templates-blog-detail-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-pages-about-us-js": () => import("./../../../src/templates/pages/aboutUs.js" /* webpackChunkName: "component---src-templates-pages-about-us-js" */),
  "component---src-templates-pages-contact-js": () => import("./../../../src/templates/pages/contact.js" /* webpackChunkName: "component---src-templates-pages-contact-js" */),
  "component---src-templates-pages-index-js": () => import("./../../../src/templates/pages/index.js" /* webpackChunkName: "component---src-templates-pages-index-js" */),
  "component---src-templates-pages-products-js": () => import("./../../../src/templates/pages/products.js" /* webpackChunkName: "component---src-templates-pages-products-js" */),
  "component---src-templates-pages-youngforce-js": () => import("./../../../src/templates/pages/youngforce.js" /* webpackChunkName: "component---src-templates-pages-youngforce-js" */)
}

